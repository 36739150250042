declare global {
    interface Window { Hyphenopoly: any; }
}

export default function configHyphenopoly() {

  const docLang = document.documentElement.lang;
  const patterns = {
      "de": "Silbentrennungsalgorithmus",
      "en": "Supercalifragilisticexpialidocious",
      "en-gb": "Supercalifragilisticexpialidocious",
      "en-us": "Supercalifragilisticexpialidocious",
  };

  const requirePatterns = {};
  requirePatterns[docLang] = patterns[docLang];

  window.Hyphenopoly = {
    require: requirePatterns,
    setup: {
      hide: "none",
      selectors: {
        ".module-block .module .title": {
          leftmin: 4,
          rightmin: 4,
          compound: "all"
        },
        ".block-region .block .header h2": {
          leftmin: 4,
          rightmin: 4,
          compound: "all"
        }
      }
    },
    paths: {
      "maindir": "/theme/eom/javascript/vendor/hyphenopoly/",
      "patterndir": "/theme/eom/javascript/vendor/hyphenopoly/patterns/",
    }
  };
}