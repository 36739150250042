/* Based on https://github.com/tristen/hoverintent */

import Delegate from 'ftdomdelegate'

class HoverIntent {
  public selector
  public state

  public inTimer
  public outTimer

  public x
  public y

  public pX
  public pY

  public el

  private delegate

  public options = {
    sensitivity: 7,
    interval: 100,
    timeout: 300
  }

  public onOut
  public onOver

  constructor(selector, onOver, onOut) {

    this.selector = selector
    this.delegate = new Delegate(document.body)

    this.options = {
      sensitivity: 7,
      interval: 100,
      timeout: 300
    }

    this.onOut = onOut
    this.onOver = onOver

    this._dispatchOver = this._dispatchOver.bind(this)
    this._dispatchOut = this._dispatchOut.bind(this)
    this._tracker = this._tracker.bind(this)
    this._compare = this._compare.bind(this)

    if (this.selector) {
      this.delegate.on('mouseover', this.selector, this._dispatchOver)
      this.delegate.on('mouseout', this.selector, this._dispatchOut)
    }
  }

  _delay(el, e) {
    if (this.outTimer) this.outTimer = clearTimeout(this.outTimer)

    if(this.state == 0)
      delete this.el

    return this.onOut.call(el, e)
  }

  _tracker(e) {
    this.x = e.clientX
    this.y = e.clientY
  }

  _compare(el, e) {
    if (this.inTimer) this.inTimer = clearTimeout(this.inTimer)
    if ((Math.abs(this.pX - this.x) + Math.abs(this.pY - this.y)) < this.options.sensitivity) {
      this.el = el
      this.state = 1
      return this.onOver.call(this.el, e)
    } else {
      this.pX = this.x;
      this.pY = this.y;
      this.inTimer = setTimeout(() => {
        this._compare(el, e);
      }, this.options.interval);
    }
  }

  _dispatchOver(e) {
    this.delegate.off('mousemove', this.selector, this._tracker)

    const el = this._getElement(e)
    const sameEl = el === this.el

    if (this.inTimer) this.inTimer = clearTimeout(this.inTimer)
    if (this.outTimer && sameEl) this.outTimer = clearTimeout(this.outTimer)

    if (!sameEl) {
      this.pX = e.clientX
      this.pY = e.clientY

      this.delegate.on('mousemove', this.selector, this._tracker)

      this.inTimer = setTimeout(() => {
        this._compare(el, e)
      }, this.options.interval)
    }

    return this
  }

  _dispatchOut(e) {
    if(this.inTimer) this.inTimer = clearTimeout(this.inTimer)
    this.delegate.off('mousemove', this.selector, this._tracker)

    const el = this._getElement(e)
    const sameEl = el === this.el

    if (this.el) {
      this.state = 0
      if(!this.outTimer) {
        this.outTimer = setTimeout(() => {
          this._delay(this.el, e)
        }, this.options.timeout)
      }
    }

    return this
  }

  _getElement(e) {
    return e.target.closest(this.selector)
  }

  remove() {
    if (!this.selector) return;
    this.delegate.off('mouseover', this.selector, this._dispatchOver)
    this.delegate.off('mouseout', this.selector, this._dispatchOut)
  };
}

export default HoverIntent
